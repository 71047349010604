import { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useNavigate, useParams } from "react-router-dom";

import { HeaderLogo } from "./HeaderLogo";
import Loading from "./loading";

export const ResultScreen = () => {
  const { patient_id, hair_analysis_id } = useParams();
  const [ready, setReady] = useState(true);
  const [hairAnalysis, setHairAnalysis] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchAnalysis();
  }, []);

  const fetchAnalysis = () => {
    setReady(false);
    fetch(
      `${process.env.REACT_APP_API}/consumer/hair_analysis/${hair_analysis_id}`,
      {}
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setHairAnalysis(response.hair_analyses);
        } else {
          setError(response.error);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setReady(true));
  };

  return (
    <>
      <HeaderLogo />
      <div className={`bg-background-20 ${ready ? `` : "hidden"}`}>
        <div
          className="w-full bg-background-1"
          style={{ borderRadius: "0px 0px 20px 20px" }}
        >
          <div
            className="mx-auto w-full"
            style={{
              maxWidth: "600px",
              minheight: "calc(100vh - 96px)",
              zIndex: "1000",
            }}
          >
            <div className="text-center">
              <p className="text-text-2">A.I. Hair Growth Tracking</p>
            </div>
            {error.image && (
              <p className="m-auto mt-1 text-sm text-center text-red-600">
                {error.image}
              </p>
            )}
            <div className="m-auto  mt-4 px-3 flex justify-center items-center flex-col">
              <img
                src={`${hairAnalysis?.[0]?.images?.portrait?.image_path}`}
                className="w-full"
                alt="captured resource"
              />
              <p className="text-text-2 m-auto w-fit mt-4">
                {hairAnalysis?.[0]?.hair_growth?.label}
              </p>
              <p className="text-text-2 m-auto w-fit mt-4">
                {hairAnalysis?.[0]?.hair_growth?.label_desc}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Loading message={"Loading Details"} ready={ready} />
    </>
  );
};
