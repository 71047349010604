import './App.css';
import { CaptureScreen } from './components/captureScreen';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import { Home } from './components/home';
import { UserInfo } from './components/userInfo';
import { ResultScreen } from './components/resultScreen';
import ClientResultsContainer from './components/ClientResult/ClientResultsContainer';
import NewWebcamComponent from './components/NewWebcamComponent';

function App() {
  return (
    <Router>
      <div className="bg-background-1 min-h-screen relative flex flex-col text-text-1 font-font-1 font-bold tracking-wide">
        <Routes>
          <Route exact path="/" Component={Home} />
          <Route exact path="/capture" Component={CaptureScreen} />
          <Route exact path="/upload" Component={UserInfo} />
          <Route exact path="/analysis/:patient_id/:hair_analysis_id" Component={ResultScreen} />
          <Route exact path="/results/:patient_id/:hair_analysis_id/:mainType/:subType" Component={ClientResultsContainer} />
          <Route exact path="/new_webcam" Component={NewWebcamComponent} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
