import { useState, useEffect } from "react";
import { IoMdCamera } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";

import { HeaderLogo } from "./HeaderLogo";

export const Home = () => {
  const [step, setStep] = useState(1);
  useEffect(() => {
    navigator.permissions
      .query({ name: "camera" })
      .then((permissionObj) => {
        console.log(permissionObj.state);
        // permission = permissionObj.state;
      })
      .catch((error) => {
        console.log("Got error :", error);
      });
  }, []);

  const tutorial1Steps = [
    "wet your hair",
    "part your hairs in the middle",
    "comb and flatten your hair",
  ];
  const tutorial2Steps = [
    "hold the phone in front of you",
    "raise the phone above your eyes",
    "follow the voice guidance",
  ];
  const videos = [
    "https://grotrack.online/videos/how_to_prepare_hair_for_hair_selfie.mp4",
    "https://grotrack.online/videos/how_to_take_hair_selfie.mp4",
  ];

  return (
    <>
      <HeaderLogo showCrossButton={true} step={step} setStep={setStep} />
      {step === 1 && <Capture setStep={setStep} />}
      {(step === 2 || step === 3) && (
        <div
          className="w-full mx-auto flex justify-center font-font-2"
          style={{ maxWidth: "600px" }}
        >
          {step === 2 && (
            <Tutorial
              actionText="I'm Ready"
              heading="How To Prepare Your Hair"
              steps={tutorial1Steps}
              vid={videos[0]}
              setStep={setStep}
              step={2}
            />
          )}
          {step === 3 && (
            <Tutorial
              actionText="Let's Go"
              heading="How To Take Hair Selfie"
              steps={tutorial2Steps}
              vid={videos[1]}
              setStep={setStep}
              step={3}
            />
          )}
        </div>
      )}
    </>
  );
};

const Capture = ({ setStep }) => {
  const location = useLocation();

  return (
    <div className="bg-background-20">
      <div className="w-full bg-background-1">
        <div
          className="mx-auto w-full"
          style={{ maxWidth: "600px", paddingBottom: "94px" }}
        >
          <div className="text-center">
            <p className="text-text-2">A.I. Hair Growth Tracking</p>
          </div>
          {location?.state?.img && (
            <div className="m-auto mt-4">
              <img
                src={location.state.img}
                className="mx-auto"
                alt="captured resource"
              />
              <p className="text-text-2 m-auto w-fit mt-4">
                Image is being processed
              </p>
            </div>
          )}
        </div>
        <div
          className="flex justify-center fixed left-0 w-full bg-background-20"
          style={{ bottom: "64px", height: "30px" }}
        >
          <div
            className="bg-background-1 w-full"
            style={{ borderRadius: "0px 0px 20px 20px" }}
          ></div>
        </div>
        <div className="flex justify-center fixed bottom-0 bg-background-20 left-0 w-full">
          <div className="box-border w-16 h-16 relative bottom-4 p-3 border-4 bg-white rounded-full border-black cursor-pointer">
            <IoMdCamera
              className="w-8 h-8"
              fill="black"
              onClick={() => setStep(2)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Tutorial = ({ actionText, heading, steps, vid, setStep, step }) => {
  const navigate = useNavigate();

  const handleStep = () => {
    if (step === 3) {
      navigate("/capture");
    }
    setStep(3);
  };
  return (
    <div className="w-full">
      <div className="text-text-3 font-medium text-center mb-4">
        <h2 className="text-lg">Hair Selfie</h2>
        <p>Powered by A.I</p>
      </div>
      <div className="w-full flex justify-center">
        <video
          className="w-full md:w-1/2"
          height="240"
          autoPlay
          muted
          playsInline
        >
          <source src={vid} type="video/mp4" />
        </video>
      </div>
      <div className="w-fit m-auto text-gray-300">
        <h1 className="text-xl my-6">{heading}</h1>
        <div className="text-gray-400 font-medium">
          <ol type="1">
            {steps.map((step, index) => {
              return <li key={index}>{`${index + 1}) ${step}`}</li>;
            })}
          </ol>
        </div>
        <div className="flex justify-center mt-6">
          <button
            type="button"
            className={`px-6 py-1 bg-background-20 rounded-lg text-text-3 cursor-pointer focus:outline-none mx-3 my-3`}
            onClick={handleStep}
          >
            {actionText}
          </button>
        </div>
      </div>
    </div>
  );
};
