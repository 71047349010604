import React, { useEffect, useState } from 'react'
import {  hairThinningUiData } from './barGraphData';

const GroIndexGraph = ({ analysis, base_analysis, mainType, hideGrowthIndex, graphType, forType }) => {

	const [hairThinningLevel, setHairThinningLevel] = useState(null)
	const [showTooltip, setShowTooltip] = useState(false);

	const toggleTooltipVisibility = () => {
		setShowTooltip(prev => !prev)
	}

	useEffect(() => {
		if (mainType === 'closeup') {
			setHairThinningLevel(analysis?.images?.closeup?.hair_thinning?.[forType] || null)
		} else if (mainType === 'global') {
			setHairThinningLevel(analysis?.images?.global?.hair_thinning || null)

		} else {
			setHairThinningLevel(null)
		}
	}, [analysis, mainType, forType]);

	return (
		<div className='flex flex-col items-center h-full md:pr-0 xl:pr-12 justify-between sm:justify-start pb-1'>
			{hairThinningLevel ? (
				<>
					<div className='relative flex justify-center'>
						<div className="text-center text-text-15  font-bold text-xxs xs:text-base xl:text-lg leading-4 xs:leading-5 tracking-wider opacity-80 w-2/3" style={{ fontFamily: 'Arial', letterSpacing: '1px', ...(window.innerWidth < 400 && {fontSize : '0.6rem'}) }}>
							Thinning Hair Condition
						</div>
					</div>

					<BarGraph hairThinningLevel={hairThinningLevel?.label} showTooltip={showTooltip} toggleTooltipVisibility={toggleTooltipVisibility} />
				</>

			) : (
				""
			)}
		</div>
	)
}

const BarGraph = ({ graphType, hairThinningLevel, hairGrowthLevel, showTooltip, toggleTooltipVisibility }) => {

	const thinningItemIndex = Object.values(hairThinningUiData).findIndex(level => level.markerKey === hairThinningLevel)

	return (
		<div className='w-full grow mt-5 xl:mt-12 flex justify-center items-center'>
			<div className='flex flex-col justify-center items-center relative'>
				{
					Object.values(hairThinningUiData).map((item, index) => {
						return (
							<React.Fragment key={index}>
								{index > 0 ? (
									<div className='h-7 w-14 xs:h-11 xs:w-20 sm:h-8 sm:w-16 lg:h-9 lg:w-16 xl:h-11 xl:w-20 border-b' style={{ borderColor: "#90676426", backgroundColor: item.levelBgColor}} ></div>
								) : ("")}
								{thinningItemIndex === index ? (
									<>
										<div onClick={toggleTooltipVisibility} className={`${item.romanValue ? `w-24 xs:py-1 xs:w-28 sm:py-0 lg:py-1 sm:w-24 xl:w-28` : `px-4 xs:px-8 sm:px-5 lg:px-8 py-2 sm:py-1 lg:py-2 w-auto`} ${item.tooltipHeading ? `cursor-pointer` : ``} relative border-2 border-slate-200 rounded-md flex justify-center items-center z-10`} style={{backgroundColor: item.markerBgColor, boxShadow: "1px 2px 3px black, -1px 0px 3px black" }}>
											<span className={`text-text-9 ${item.romanValue ? `mr-1 text-base` : `font-semibold`}`} style={{ fontSize: '0.95rem' }}>{item.markerText}</span>
											<h1 className='text-text-9' style={{ fontSize: '1.80rem', lineHeight: '2rem' }}>{item.romanValue}</h1>
											{showTooltip && item.tooltipHeading ? (
												<div className="absolute top-10 flex flex-col items-center mb-6 group-hover:flex">
													<div className="w-6 h-6 bg-white" style={{ transform: "rotate(45deg)" }}></div>
													<div className="relative z-10 p-2 w-max bg-white text-center" style={{ transform: 'translate(0, -12px)' }}>
														<p className='text-xs xs:text-sm font-bold leading-none text-black mb-1 whitespace-nowrap'>{hairThinningLevel}</p>
														<p className='font-medium text-xs text-black whitespace-nowrap'>{item.tolltipDescription}</p>
													</div>

												</div>
											) : ("")}

										</div>
									</>

								) : ("")}
							</React.Fragment>
						)
					})
				}

			</div>
		</div>
	)
}

export default GroIndexGraph