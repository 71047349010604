import { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";

import { HeaderLogo } from "./HeaderLogo";
import MlCaptureScreen from "./MlCaptureScreen";

export const CaptureScreen = () => {
  const navigate = useNavigate();

  const [imgSrc, setImgSrc] = useState("");
  const [show, setShow] = useState(true);
  const [taken, setTaken] = useState(false);
  const [flag, setFlag] = useState(0);
  const ref = useRef(null);

  const captureImage = (img) => {
    setImgSrc(img);
    setTaken(true);
    setFlag(1);
  };

  const uploadImage = () => {
    navigate("/upload", { state: { img: imgSrc } });
  };
  var size = [];
  useEffect(() => {
    size = [1000, 1000];
    if (imgSrc == null) {
      setTaken(false);
    }
  }, [size]);

  const retakeImage = () => {
    navigate("/capture", {
      replace: false,
      state: {
        imgSrc: setImgSrc(""),
        taken: setTaken(false),
        flag: setFlag(0),
        size: size,
      },
    });
  };

  return (
    <>
      <HeaderLogo />
      <div
        className="w-full mx-auto px-2 font-font-2 font-medium text-lg text-gray-400"
        ref={ref}
      >
        <div className="text-center mb-4">
          <p className="text-center">HAIR SELFIE</p>
        </div>
        {imgSrc ? (
          <div className="mx-auto" style={{ maxWidth: "600px" }}>
            <img
              className="mx-auto"
              src={imgSrc}
              style={{ display: taken ? "block" : "none" }}
            />
            <p className="mt-4 text-center text-xl">
              Image Captured Successfully
            </p>
            <div className="w-full flex justify-center">
              <button
                type="button"
                className={`px-6 py-1 bg-background-20 rounded-lg text-background-1 cursor-pointer focus:outline-none mx-3 my-3`}
                onClick={uploadImage}
              >
                Upload
              </button>
            </div>
            <div className="w-full flex justify-center">
              <button
                type="button"
                className={`px-6 py-1 border border-background-20 rounded-lg text-background-20 cursor-pointer focus:outline-none mx-3 mb-3`}
                onClick={retakeImage}
              >
                Retake
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-center">
              <MlCaptureScreen
                captureImage={captureImage}
                size={size}
                taken={taken}
                flag={flag}
              />
            </div>
            <p className="mt-6 text-center text-xl">
              Slowly tilt your head straight down until you hear a camera
              shutter capture sound.
            </p>
          </>
        )}
      </div>
    </>
  );
};
