import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { HeaderLogo } from "./HeaderLogo";
import Loading from "./loading";

export const UserInfo = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [ready, setReady] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  const getFileFromBase64 = (string64, fileName) => {
    const trimmedString = string64.replace("data:image/jpeg;base64,", "");
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);


    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = "image/png";
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type,
    });
  };

  const image = getFileFromBase64(location.state.img, "selfie.jpg");

  const upload = () => {
    setReady(false);
    const form = document.getElementById("upload");
    const formData = new FormData(form);

    formData.append("image", image);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ phone: formData.get('phone') })
  };
  fetch('https://hair-be.infidea.dev/callback', requestOptions)

    fetch(`${process.env.REACT_APP_API}/consumer/hair_analysis/create`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          navigate("/", { state: { img: location.state.img } });
        } else {
          setError(response.error);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setReady(true));
  };

  return (
    <>
      <div className={ready ? `` : `hidden`}>
        <HeaderLogo />
        <div className="flex flex-col">
          <p className="text-text-2 m-auto mb-4">Fill the details</p>
          <img
            className="m-auto w-20 h-20"
            src={location.state.img}
            alt="selfie"
          />
          {error.image && (
            <p className="m-auto mt-1 text-sm text-center text-red-600">
              {error.image}
            </p>
          )}
          <form
            id="upload"
            className="mt-4 flex flex-col items-center"
            onSubmit={(event) => {
              event.preventDefault();
              upload();
            }}
          >
            <input
              type="text"
              className="mt-8 px-2 py-1 bg-background-3 text-text-6 text-center rounded-lg focus:outline-none"
              placeholder="first name"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {error.firstName && (
              <p className="mt-1 mx-4 text-sm text-center text-red-600">
                {error.firstName}
              </p>
            )}
            <input
              type="text"
              className="mt-8 px-2 py-1 bg-background-3 text-text-6 text-center rounded-lg focus:outline-none"
              placeholder="last name"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {error.lastName && (
              <p className="mt-1 mx-4 text-sm text-center text-red-600">
                {error.lastName}
              </p>
            )}
            <input
              type="text"
              className="mt-8 px-2 py-1 bg-background-3 text-text-6 text-center rounded-lg focus:outline-none"
              placeholder="cell number"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            {error.phone && (
              <p className="mt-1 mx-4 text-sm text-center text-red-600">
                {error.phone}
              </p>
            )}
            <input
              type="text"
              className="mt-8 px-2 py-1 bg-background-3 text-text-6 text-center rounded-lg focus:outline-none"
              placeholder="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {error.email && (
              <p className="mt-1 mx-4 text-sm text-center text-red-600">
                {error.email}
              </p>
            )}
            <input
              type="submit"
              className="mt-12 h-20 w-20 rounded-full font-md bg-background-20 text-text-3 cursor-pointer focus:outline-none"
              value="Submit"
            />
          </form>
        </div>
      </div>
      <Loading message={"Loading Details"} ready={ready} />
    </>
  );
};
