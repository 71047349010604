import React, { useRef } from 'react'
import { useState, useEffect } from 'react';
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useLocation } from 'react-router-dom';
import { Link, useParams, useMatch } from 'react-router-dom';


const ImageLoader = ({ img, onDoubleClick, secondaryImg, showBlackTint, bordered, showMaskAssessment }) => {
	// const [loading, setLoading] = useState(true);
	const [message, setMessage] = useState('');
	const [secondaryImgMessage, setSecondaryImgMessage] = useState('');
	const [showSecondaryImage, setShowSecondaryImage] = useState(true);
	const location = useLocation()
	const isDevMode = new URLSearchParams(location.search).get("mode") === 'dev';
	const { patient_id, hair_analysis_id, graphType } = useParams();

	const globalMatch = useMatch("/patient/:patient_id/consult/:hair_analysis_id/global/:subType");
  	const closeupMatch = useMatch("/patient/:patient_id/consult/:hair_analysis_id/closeup/:subType");
	const clientResultsMatch = useMatch("/results/:patient_id/:hair_analysis_id/:mainType/:subType");

	useEffect(() => {
		setMessage(getMessage(img));
		setSecondaryImgMessage(getMessage(secondaryImg));
	}, [img, secondaryImg]);

	const getMessage = (img) => {
		if (!img) {
			return 'No image found';
		} else if (!img.image_path || img.image_path === '0') {
			return <p className='mb-2'>Image is being processed</p>;
		} else if (img.status === 'invalidated') {
			return <ShowInvalidReason img={img} />;
		} else if (img.status === 'invalid') {
			return <p className="text-center text-lg mb-2" style={{ color: "#EF5656" }}>Pending validation</p>;
		} else if (img.status === 'approved') {
			return <p className="text-center text-turquoise-1 text-lg mb-2">Validated</p>;
		} else {
			return "";
		}
	}

	const handleDoublieClick = () => {
		if (onDoubleClick) {
			onDoubleClick();
		}
	}

	const handleToggleSecondaryImage = () => {
		if (secondaryImg) {
			setShowSecondaryImage(prev => !prev);
		}
	}

	return (
		<>
			<div className="relative flex flex-col items-center pt-7 w-full">
				{
					message && message !== 'No image found' && <p className='absolute top-0 z-50' style={{ transform: 'translate(0, -5px)' }}>{message}</p>
				}
				{
					!message && secondaryImgMessage !== 'No image found' && secondaryImgMessage && <p className='absolute top-0 z-50' style={{ transform: 'translate(0, -5px)' }}>{secondaryImgMessage}</p>
				}
				{message && message === 'No image found' ? (
					<div className='hidden sm:flex justify-center h-56 xs:h-80 sm:h-60 lg:h-80 xl:h-96 sm:mt-2 lg:mt-0' style={{marginBottom: globalMatch || closeupMatch ? '74px' : '0px'}}>
						<p className="text-center text-gray-400 h-full">{message}</p>
					</div>
				) : (
					<div className='flex flex-col justify-center items-center'>
						<div className='relative' style={{width: "fit-content"}}>
							<img
								src={`${img?.image_path}?width=312`}
								alt={`Paint ${img?.paint} - ${img?.mainType} ${img?.subType}`}
								className="cursor-pointer box-border w-auto h-56 xs:h-80 sm:h-64 lg:h-80 xl:h-96"
								style={{ border: bordered ? '1px solid #6c6f75' : 'none' }}
								onDoubleClick={handleDoublieClick}
								onClick={handleToggleSecondaryImage}
							/>
							{img?.status !== 'invalid' && secondaryImg && secondaryImg?.image_path && secondaryImg?.image_path !== '0' && showSecondaryImage && (
								<img
									src={`${secondaryImg?.image_path}?width=312`}
									className={` cursor-pointer box-border ${showBlackTint ? `bg-black bg-opacity-50` : ''}`}
									style={{
										position: 'absolute',
										top: 0,
										left: img?.ml_data?.x_min_assessment ? img?.ml_data?.x_min_assessment * 100 + '%' : 'auto',
										height: '100%',
										objectFit: 'cover',
									}}
									onClick={handleToggleSecondaryImage}
									onDoubleClick={handleDoublieClick}
								/>
							)}
						</div>
						{isDevMode && img.ml_data && <div className='mt-6'>
							{Object.keys(img.ml_data).map((item, index) => {
								return (
									<h1 key={index} className="capitalize mb-3 break-words  font-bold text-text-9 text-opacity-85 text-sm leading-5 opacity-80" style={{ letterSpacing: '1.6px' }}>
										{item.split("_").join(" ")} : {img?.ml_data?.[item]}
									</h1>
								)
							})}
						</div>}
						{(globalMatch || closeupMatch) && (
							<div className='w-full text-center mt-5 hidden sm:block'>
							<Link to={`/patient/${patient_id}/consult/${hair_analysis_id}/gro_reprot`} className="w-full block bg-transparent border border-solid py-2 lg:py-3 sm:px-0 lg:px-4 font-bold text-base xxs:text-lg font-sans tracking-wider leading-5 uppercase" style={{ color: '#D0D4D7', borderColor: 'rgba(246,247,250,0.4)' }}>Gro Reports</Link>
						</div>
							)}
					</div>
				)}
			</div>
		</>
	);
};

const ShowInvalidReason = ({ img }) => {
	const [showPopup, setShowPopup] = useState(false);
	const componentRef = useRef(null);

	const closePopup = () => {
		setShowPopup(prev => !prev);
	}

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (componentRef.current && !componentRef.current.contains(event.target)) {
				setShowPopup(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<p className="text-center text-lg flex items-center gap-4 relative mb-2" style={{ color: "#EF5656" }} ref={componentRef}>
			Invalid 
			
			<div className='relative'>
			<AiOutlineExclamationCircle className='inline text-white cursor-pointer' onClick={closePopup} />
			{
				showPopup && (
					window.innerWidth < 640 ? (
						<div className="absolute top-8 flex flex-col items-center mb-6 group-hover:flex left-1/2" style={{transform: 'translate(-100%, 0)'}}>
							<div className="w-6 h-6 bg-white absolute right-0" style={{ transform: "rotate(45deg) translate(9px, -9px)" }}></div>
							<div className="relative z-10 p-2 w-max bg-white text-center rounded-lg" style={{ transform: 'translate(24px, 12px)' }}>
								{
									img.reason.map((reason, i) => (
										<p key={i} className='text-base font-bold leading-none text-black whitespace-nowrap'>{reason}</p>
									))
								}
							</div>
						</div>
					) : (
						<div className="absolute top-8 flex flex-col items-center mb-6 group-hover:flex left-1/2" style={{transform: 'translate(-50%, 0)'}}>
							<div className="w-6 h-6 bg-white" style={{ transform: "rotate(45deg)" }}></div>
							<div className="relative z-10 p-2 w-max bg-white text-center rounded-lg" style={{ transform: 'translate(0, -12px)' }}>
								{
									img.reason.map((reason, i) => (
										<p key={i} className='text-base font-bold leading-none text-black whitespace-nowrap'>{reason}</p>
									))
								}
							</div>
						</div>
					)
					
						) 
			}
				</div>
		</p>
	);
}

export default React.memo(ImageLoader);
