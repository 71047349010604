import React from "react";

export default function Loading(props) {
  return (
    <main
      className={`flex p-6 font-font-2 font-normal ${
        props.ready ? "hidden" : ""
      }`}
    >
      <figure className="m-auto">
        <svg
          className="h-24 mx-auto animate-spin text-form-text-accent"
          viewBox="0 0 20 20"
        >
          <path
            fill="currentColor"
            d="M12 10a2 2 0 0 1-3.41 1.41A2 2 0 0 1 10 8V0a9.97 9.97 0 0 1 10 10h-8zm7.9 1.41A10 10 0 1 1 8.59.1v2.03a8 8 0 1 0 9.29 9.29h2.02zm-4.07 0a6 6 0 1 1-7.25-7.25v2.1a3.99 3.99 0 0 0-1.4 6.57 4 4 0 0 0 6.56-1.42h2.1z"
          />
        </svg>
        <figcaption className="mt-4 p-4 text-center">
          <strong className="text-2xl font-normal">
            {props.message}. Please Wait...
          </strong>
        </figcaption>
      </figure>
    </main>
  );
}
