import Logo from '../assets/images/logo-1.png';
import { RxCross2 } from 'react-icons/rx';

export const HeaderLogo = ({ showCrossButton = false, setStep, step }) => {
    return (
        <div className='flex justify-center py-4'>
            {(showCrossButton && step !== 1) &&
                <div className='absolute left-0 h-12'>
                    <RxCross2 onClick={() => setStep(step - 1)} className='w-8 h-8 mx-2 text-text-2 cursor-pointer' />
                </div>
            }
            <img className='h-16' src={Logo} alt='Logo' />
        </div>
    )
}